.Animation {
	width: 100%;
	max-width: 100vw;
	height: 100%;
	max-height: 100vh;
	background-color: transparent;
}

/** These three lines are the only ones you need! Credit @Una Kravets**/
.Animation-container {
	display: -ms-grid;
	display: grid;
	place-items: center;
}

.Animation-container-one {
	background: #005f9c;
	-webkit-animation: right 1s infinite ease-in-out;
	-moz-animation: right 1s infinite ease-in-out;
	animation: right 1s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

.Animation-container-two {
	background: #366481;
	-webkit-animation: left 1.1s infinite ease-in-out;
	-moz-animation: left 1.1s infinite ease-in-out;
	animation: left 1.1s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

.Animation-container-three {
	background: #516773;
	-webkit-animation: right 1.05s infinite ease-in-out;
	-moz-animation: right 1.05s infinite ease-in-out;
	animation: right 1.05s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

.Animation-container-four {
	background: #886c58;
	-webkit-animation: left 1.15s infinite ease-in-out;
	-moz-animation: left 1.15s infinite ease-in-out;
	animation: left 1.15s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

.Animation-container-five {
	background: #a36f4a;
	-webkit-animation: right 1.1s infinite ease-in-out;
	-moz-animation: right 1.1s infinite ease-in-out;
	animation: right 1.1s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

.Animation-container-six {
	background: #d9742f;
	-webkit-animation: left 1.05s infinite ease-in-out;
	-moz-animation: left 1.05s infinite ease-in-out;
	animation: left 1.05s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

.Animation-container-seven {
	background: #f47721;
	-webkit-animation: right 1s infinite ease-in-out;
	-moz-animation: right 1s infinite ease-in-out;
	animation: right 1s infinite ease-in-out;
	width: 15px;
	height: 15px;
	margin: 10px auto;
	border-radius: 50px;
}

@-webkit-keyframes right {
	0% {
		-webkit-transform: translate(-15px);
	}

	50% {
		-webkit-transform: translate(15px);
	}

	100% {
		-webkit-transform: translate(-15px);
	}
}

@-webkit-keyframes left {
	0% {
		-webkit-transform: translate(15px);
	}

	50% {
		-webkit-transform: translate(-15px);
	}

	100% {
		-webkit-transform: translate(15px);
	}
}

@-moz-keyframes right {
	0% {
		-moz-transform: translate(-15px);
	}

	50% {
		-moz-transform: translate(15px);
	}

	100% {
		-moz-transform: translate(-15px);
	}
}

@-moz-keyframes left {
	0% {
		-moz-transform: translate(15px);
	}

	50% {
		-moz-transform: translate(-15px);
	}

	100% {
		-moz-transform: translate(15px);
	}
}

@keyframes right {
	0% {
		transform: translate(-15px);
	}

	50% {
		transform: translate(15px);
	}

	100% {
		transform: translate(-15px);
	}
}

@keyframes left {
	0% {
		transform: translate(15px);
	}

	50% {
		transform: translate(-15px);
	}

	100% {
		transform: translate(15px);
	}
}
