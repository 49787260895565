@import url('https://p.typekit.net/p.css?s=1&k=woe8jmk&ht=tk&f=4399.4624.39182.39183.39184.39185.39186.39187&a=54301672&app=typekit&e=css');

*:focus {
	outline: 0;
}

*::-webkit-scrollbar {
	width: 0.4em;
}


*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgb(0 0 0 / 0%);
}

*::-webkit-scrollbar-thumb {
	background-color: rgb(244 119 33 / 80%);
	border-radius: 0.2em;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: rgb(244 119 33);
}

a {
	text-decoration: none;
}

@font-face {
	font-family: ff-nuvo-web-pro;
	src: url('https://use.typekit.net/af/a2ab6d/00000000000000007735996f/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/a2ab6d/00000000000000007735996f/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3')
		format('woff'),
		url('https://use.typekit.net/af/a2ab6d/00000000000000007735996f/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3')
		format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: ff-nuvo-web-pro;
	src: url('https://use.typekit.net/af/e6cd91/000000000000000077359959/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/e6cd91/000000000000000077359959/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3')
		format('woff'),
		url('https://use.typekit.net/af/e6cd91/000000000000000077359959/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3')
		format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: calibri;
	src: url('https://use.typekit.net/af/bdd682/00000000000000007735bbe9/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/bdd682/00000000000000007735bbe9/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3')
		format('woff'),
		url('https://use.typekit.net/af/bdd682/00000000000000007735bbe9/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n7&v=3')
		format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: calibri;
	src: url('https://use.typekit.net/af/9bc41b/00000000000000007735bbeb/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i7&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/9bc41b/00000000000000007735bbeb/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i7&v=3')
		format('woff'),
		url('https://use.typekit.net/af/9bc41b/00000000000000007735bbeb/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i7&v=3')
		format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: calibri;
	src: url('https://use.typekit.net/af/cfc06a/00000000000000007735bbef/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i4&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/cfc06a/00000000000000007735bbef/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i4&v=3')
		format('woff'),
		url('https://use.typekit.net/af/cfc06a/00000000000000007735bbef/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i4&v=3')
		format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: calibri;
	src: url('https://use.typekit.net/af/b95b9d/00000000000000007735bbf2/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n3&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/b95b9d/00000000000000007735bbf2/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n3&v=3')
		format('woff'),
		url('https://use.typekit.net/af/b95b9d/00000000000000007735bbf2/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n3&v=3')
		format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: calibri;
	src: url('https://use.typekit.net/af/1cb4b3/00000000000000007735bbf6/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i3&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/1cb4b3/00000000000000007735bbf6/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i3&v=3')
		format('woff'),
		url('https://use.typekit.net/af/1cb4b3/00000000000000007735bbf6/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=i3&v=3')
		format('opentype');
	font-display: auto;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: calibri;
	src: url('https://use.typekit.net/af/5c4f69/00000000000000007735bbfb/30/l?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3')
		format('woff2'),
		url('https://use.typekit.net/af/5c4f69/00000000000000007735bbfb/30/d?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3')
		format('woff'),
		url('https://use.typekit.net/af/5c4f69/00000000000000007735bbfb/30/a?primer=057d1dff47772f391f5f6c189aa213bb70116047eaffac2e52d3626ff163c8d1&fvd=n4&v=3')
		format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}
